import {
  assureArray,
  escapeXML,
  isPrimitive,
  useSecondEffect,
} from "@adv-libs/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { addQueryToUrl, getQuery } from "../../utils/path";
import { getHistory } from "../Router/history";
import toastError from "../ToastMessage/toastError";

export interface RouterXMLFilterOptions {
  filterProperties: string[];
  fetchFilters: (filterXML: string) => Promise<any>;
}

const useRouterXMLFilter = (options) => {
  const location = useLocation();
  const query = getQuery(location.pathname + location.search);

  const initialFiltersData = useMemo(() => {
    const result = {};
    for (const propertyName of options.filterProperties) {
      result[propertyName] = [];
    }
    return result;
  }, [options.filterProperties]);

  const hasFilter = useMemo(() => {
    let hasFilter = false;
    for (const propertyName of options.filterProperties) {
      const filterValue = query[propertyName];
      //if filter value is empty string, it's still value and xml tags must be formed
      if (typeof filterValue !== "undefined") {
        hasFilter = true;
        break;
      }
    }

    return hasFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const [isLoading, setIsLoading] = useState(false);
  const filterValueRef = useRef<Record<string, any[]>>({});
  const [filterValue, setFilterValue] = useState<Record<string, any[]>>({});
  const [showFilters, setShowFilters] = useState(hasFilter);
  const [searchFilter, setSearchFilter] = useState(false);

  const [filtersData, setFiltersData] = useState<any>(initialFiltersData);

  const confirmFilter = useCallback(() => {
    /** Filters create from the reference to avoid the memorization */
    const query = {};

    for (const propertyName of options.filterProperties) {
      const filterValue = filterValueRef.current[propertyName];
      query[propertyName] = filterValue
        ? assureArray(filterValue).map((item) => item.id)
        : undefined;
    }

    const history = getHistory();

    const url = addQueryToUrl(location.pathname + location.search, query);

    history.replace(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const setFilter = useCallback((propertyName: string, value: any[]) => {
    setFilterValue((state) => {
      return { ...state, [propertyName]: value };
    });
  }, []);

  const clearFilter = useCallback(() => {
    setFilterValue({});
    setSearchFilter(false);
    setTimeout(() => {
      confirmFilter();
    }, 0);
  }, [confirmFilter]);

  const filterXML = useMemo(() => {
    const xml = ["<filters>"];
    for (const propertyName of options.filterProperties) {
      const filterValue = query[propertyName];
      //if filter value is empty string, it's still value and xml tags must be formed
      if (typeof filterValue !== "undefined") {
        xml.push(`<${propertyName}>`);
        assureArray(filterValue).forEach((item) => {
          xml.push(`<r>`);
          xml.push(`<k>${escapeXML(item)}</k>`);
          xml.push(`</r>`);
        });
        xml.push(`</${propertyName}>`);
      }
    }
    xml.push("</filters>");
    return xml.join("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const fetchFilters = useCallback(async () => {
    try {
      setIsLoading(true);
      const filters = await options.fetchFilters(filterXML);
      setFiltersData(filters);
    } catch (err) {
      toastError(err);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterXML, options.fetchFilters]);

  const populateFilterValue = useCallback(() => {
    const filterValues = {};

    for (const propertyName of options.filterProperties) {
      const filterValue = query[propertyName];
      //if filter value is empty string, it's still value and xml tags must be formed
      if (typeof filterValue !== "undefined") {
        const itemsById = {};

        filtersData[propertyName].forEach((item) => {
          itemsById[item.id] = item;
        }, {});

        const populated = assureArray(filterValue).map((itemOrId) => {
          if (isPrimitive(itemOrId)) {
            return itemsById[itemOrId];
          } else {
            return itemOrId;
          }
        });

        filterValues[propertyName] = populated;
      }

      setFilterValue(filterValues);
    }
  }, [filtersData, options.filterProperties, query]);

  useEffect(() => {
    /** Update references from the real select component value */
    filterValueRef.current = filterValue;

    for (const propertyName in filterValue) {
      propertyName === "search"
        ? setSearchFilter(true)
        : setSearchFilter(false);
    }
  }, [filterValue]);

  useSecondEffect(() => {
    if (showFilters || searchFilter) {
      fetchFilters();
    } else {
      clearFilter();
    }
  }, [fetchFilters, clearFilter, showFilters, searchFilter]);

  useEffect(() => {
    if (showFilters) {
      fetchFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSecondEffect(() => {
    if (showFilters) {
      populateFilterValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData]);

  return {
    confirmFilter,
    clearFilter,
    filterXML,
    filterValue,
    setFilter,
    hasFilter,
    showFilters,
    setShowFilters,
    isLoading,
    filtersData,
  };
};

export default useRouterXMLFilter;
